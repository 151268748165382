import APIClient from "@/services/APIClient";
import TableAPIModel from "@/apiModels/TableAPIModel";
import { useQuery } from "@tanstack/react-query";
import BaseServices from "@/services/BaseServices";

interface Props {
  eventId?: string;
  areaId?: string;
}

const useSeats = ({ eventId, areaId }: Props) => {
  const apiClient = new APIClient<TableAPIModel>(
    BaseServices.SEAT_RESERVATION_SERVICE,
    `/user-app/seat/event/${eventId}/list-seat`
  );

  return useQuery<TableAPIModel[], Error>({
    queryKey: ["tables", eventId, areaId],
    queryFn: () =>
      apiClient.getList({
        area: areaId
      }),
    enabled: !!eventId && !!areaId,
    retry: false
  });
};

export default useSeats;
