import APIClient from "@/services/APIClient";
import { useQuery } from "@tanstack/react-query";
import BaseServices from "@/services/BaseServices";

const useBookingTicketPDF = (bookingId: string) => {
  const apiClient = new APIClient<File>(
    BaseServices.SEAT_RESERVATION_SERVICE,
    `/reservation/${bookingId}/ticket-pdf`
  );
  return useQuery<File, Error>({
    queryKey: ["reservation-ticket-pdf", bookingId],
    queryFn: apiClient.getFile,
    enabled: !!bookingId
  });
};

export default useBookingTicketPDF;
