import React from "react";
import IconButton from "@/components/ui/IconButton";
import CaretLeftIcon from "@/components/icons/CaretLeftIcon";
import Card from "@/components/ui/Card";
import CalendarIcon from "@/components/icons/CalendarIcon";
import CaretRightIcon from "@/components/icons/CaretRightIcon";
import EventDetailAPIModel from "@/apiModels/ReservationScheduleAPIModel";
import {
  StyledReservationDateSelector,
  StyledReservationDateSelectorCardContent,
  StyledReservationDateSelectorCardWrapper
} from "@/components/ui/ReservationDateSelector/ReservationDateSelector.styled.ts";

type Props = {
  reservationSchedule: EventDetailAPIModel;
};

const ReservationDateSelector: React.FunctionComponent<Props> = ({
  reservationSchedule
}) => {
  return (
    <StyledReservationDateSelector id={"reservation-date-selector"}>
      <h2>Reservation date</h2>
      <StyledReservationDateSelectorCardWrapper>
        <IconButton onClick={() => {}} icon={<CaretLeftIcon />} />

        <Card accent>
          <StyledReservationDateSelectorCardContent>
            <CalendarIcon />

            <span>{reservationSchedule.date}</span>
          </StyledReservationDateSelectorCardContent>
        </Card>
        <IconButton onClick={() => {}} icon={<CaretRightIcon />} />
      </StyledReservationDateSelectorCardWrapper>
    </StyledReservationDateSelector>
  );
};

export default ReservationDateSelector;
