import React from "react";
import Card from "@/components/ui/Card";
import {
  StyledInfoIcon,
  StyledInquiryDetails,
  StyledInquiryDetailsContent,
  StyledInquiryDetailsContentWrapper,
  StyledInquiryDetailsWrapper,
  StyledInquiryServiceIcon,
  StyledInquiryWrapper
} from "@/components/ui/ForMoreInquiry/ForMoreInquiry.styled.ts";
import QueryIcon from "@/assets/icons/QueryIcon.svg";
import EmailIcon from "@/assets/icons/EmailIcon.svg";
import PhoneIcon from "@/assets/icons/PhoneIcon.svg";

const ForMoreInquiry: React.FunctionComponent = () => {
  return (
    <Card
      accent={true}
      margin={{
        top: "1.25rem",
        bottom: "1.25rem"
      }}
      padding={{
        top: "0.625rem",
        left: "0.625rem",
        bottom: "0.625rem",
        right: "0.625rem"
      }}
    >
      <StyledInquiryWrapper>
        <StyledInfoIcon
          height={40}
          width={40}
          src={QueryIcon}
          alt={"query-icons"}
        />

        <StyledInquiryDetails>
          <StyledInquiryDetailsContent $heading>
            For More Inquiry
          </StyledInquiryDetailsContent>
          <StyledInquiryDetailsContentWrapper>
            {/* Phone Icon */}
            <StyledInquiryServiceIcon
              src={PhoneIcon}
              height={15}
              width={15}
              alt={"phone-icons"}
            />

            {/* Phone Number */}
            <StyledInquiryDetailsWrapper>
              <StyledInquiryDetailsContent href={"tel://+977-9856052581"}>
                9856052581&nbsp;|
              </StyledInquiryDetailsContent>

              <StyledInquiryDetailsContent href={"tel://+977-9846511962"}>
                &nbsp;9846511962
              </StyledInquiryDetailsContent>
            </StyledInquiryDetailsWrapper>
          </StyledInquiryDetailsContentWrapper>
          <StyledInquiryDetailsContentWrapper>
            {/* Email Icon */}
            <StyledInquiryServiceIcon
              src={EmailIcon}
              height={15}
              width={15}
              alt={"email-icons"}
            />

            {/* Email Address Number */}
            <StyledInquiryDetailsContent
              href={"mailto://contact@revelarena.com"}
            >
              contact@revelarena.com
            </StyledInquiryDetailsContent>
          </StyledInquiryDetailsContentWrapper>
        </StyledInquiryDetails>
      </StyledInquiryWrapper>
    </Card>
  );
};

export default ForMoreInquiry;
