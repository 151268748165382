import React, { useEffect, useState } from "react";
import Card from "@/components/ui/Card";
import {
  StyledHorizontalLine,
  StyledReservationSummaryContainer,
  StyledReservationSummaryItem,
  StyledReservationSummaryItemAction,
  StyledReservationSummaryItemActionWrapper,
  StyledReservationSummaryItemPrice,
  StyledReservationSummaryItemWrapper,
  StyledReservationSummaryTableName,
  StyledTicketSummaryEventTitle,
  StyledTicketSummaryPhasePriceCalculation,
  StyledTicketSummaryPhasePriceListItem,
  StyledTicketSummaryPhasePriceListWrapper,
  StyledTicketSummaryTotalAmountContainer,
  StyledTicketSummaryTotalAmountCount,
  StyledTicketSummaryTotalAmountText,
  StyledTicketSummaryTotalTicketContainer,
  StyledTicketSummaryTotalTicketCount,
  StyledTicketSummaryTotalTicketText,
  StyledTicketSummaryWrapper
} from "@/components/ui/ReservationSummary/ReservationSummary.styled.ts";
import SectionTitle from "@/components/ui/SectionTitle";
import useBulkTableCartStore from "@/stores/BulkTableCartStore.ts";
import ReservationInfoModal from "@/components/ui/Modals/ReservationInfoModal";
import DeleteReservationModal from "@/components/ui/Modals/DeleteReservationModal";
import updateSeatColor from "@/utils/updateSeatColor.ts";
import TableAPIModel from "@/apiModels/TableAPIModel";
import EventDetailAPIModel from "@/apiModels/ReservationScheduleAPIModel";

type Props = {
  reservationSchedule: EventDetailAPIModel;
};

const ReservationSummary: React.FunctionComponent<Props> = ({
  reservationSchedule
}) => {
  const { reservations, removeReservationById, totalAmount, setTotalAmount } =
    useBulkTableCartStore((store) => ({
      reservations: store.reservationTables,
      removeReservationById: store.removeReservationTableById,
      totalAmount: store.totalAmount,
      setTotalAmount: store.setTotalAmount
    }));
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState<boolean>(false);
  const [selectedReservation, setSelectedReservation] = useState<string>("");
  const [focusedResObj, setFocusedResObj] = useState<TableAPIModel | null>(
    null
  );

  useEffect(() => {
    setTotalAmount(
      reservations.reduce((acc, cur) => acc + Number(cur.price), 0)
    );
    return () => {
      setTotalAmount(0);
    };
  }, [reservations, setTotalAmount]);

  const removeReservation = (id: string) => {
    initModalData(id);
    setIsDeleteModalOpen(true);
    setSelectedReservation(id);
  };

  const onConfirmRemoveReservation = () => {
    removeReservationById(selectedReservation);
    const reservationForDeletion = reservations.find(
      (seat) => seat.id === selectedReservation
    );

    const resArray = JSON.parse(
      String(localStorage.getItem("selectedReservations"))
    ) as unknown as TableAPIModel[];

    const updatedResArray = resArray.filter(
      (seat) => seat.id !== selectedReservation
    );
    localStorage.setItem(
      "selectedReservations",
      JSON.stringify(updatedResArray)
    );

    if (reservationForDeletion) {
      updateSeatColor({
        seat: reservationForDeletion.serial_no,
        color: ""
      });
    }
    onCancelRemoveReservation();
  };
  const onCancelRemoveReservation = () => {
    setIsDeleteModalOpen(false);
    setSelectedReservation("");
    disposeModalData();
  };

  const onOkInfoReservation = () => {
    setIsInfoModalOpen(false);
    setSelectedReservation("");
    disposeModalData();
  };

  const showDetail = (id: string) => {
    initModalData(id);
    setIsInfoModalOpen(true);
    setSelectedReservation(id);
  };

  const initModalData = (id: string) => {
    setFocusedResObj(reservations.find((seat) => seat.id === id) || null);
  };

  const disposeModalData = () => {
    setFocusedResObj(null);
  };

  return (
    <StyledReservationSummaryContainer>
      <SectionTitle>Reservation Summary</SectionTitle>
      <Card
        accent={true}
        margin={{
          top: "1.25rem",
          bottom: "1.25rem"
        }}
        padding={{
          top: "0.625rem",
          left: "0.625rem",
          bottom: "0.625rem",
          right: "0.625rem"
        }}
      >
        <StyledTicketSummaryWrapper>
          <StyledTicketSummaryEventTitle>
            {reservationSchedule.date}, {reservationSchedule.name}
          </StyledTicketSummaryEventTitle>

          <StyledHorizontalLine />

          <StyledReservationSummaryItemWrapper>
            {reservations.map((reservation) => (
              <StyledReservationSummaryItem key={reservation.serial_no}>
                <StyledTicketSummaryPhasePriceListItem>
                  <StyledTicketSummaryPhasePriceListWrapper>
                    <StyledReservationSummaryTableName>
                      {reservation.serial_no}
                    </StyledReservationSummaryTableName>
                    <StyledTicketSummaryPhasePriceCalculation>
                      {/* Reservation Price */}
                      <StyledReservationSummaryItemPrice>
                        Rs. {Number(reservation.price).toLocaleString("en-In")}
                      </StyledReservationSummaryItemPrice>

                      {/* Actions */}
                      <StyledReservationSummaryItemActionWrapper>
                        <StyledReservationSummaryItemAction
                          onClick={() => showDetail(reservation.id)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              d="M8 5.87809H8.00778M1 8.21143C1 9.13068 1.18106 10.0409 1.53284 10.8902C1.88463 11.7395 2.40024 12.5112 3.05025 13.1612C3.70026 13.8112 4.47194 14.3268 5.32122 14.6786C6.1705 15.0304 7.08075 15.2114 8 15.2114C8.91925 15.2114 9.82951 15.0304 10.6788 14.6786C11.5281 14.3268 12.2997 13.8112 12.9497 13.1612C13.5998 12.5112 14.1154 11.7395 14.4672 10.8902C14.8189 10.0409 15 9.13068 15 8.21143C15 6.35491 14.2625 4.57443 12.9497 3.26168C11.637 1.94892 9.85652 1.21143 8 1.21143C6.14348 1.21143 4.36301 1.94892 3.05025 3.26168C1.7375 4.57443 1 6.35491 1 8.21143Z"
                              stroke="#865CD0"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7.22217 8.21143H7.99995V11.3225H8.77772"
                              stroke="#865CD0"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </StyledReservationSummaryItemAction>

                        <StyledReservationSummaryItemAction
                          onClick={() => removeReservation(reservation.id)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              d="M15 3.99746C12.41 3.76646 9.80444 3.64746 7.20667 3.64746C5.66667 3.64746 4.12667 3.71746 2.58667 3.85746L1 3.99746"
                              stroke="#E53535"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M5.27783 3.29043L5.44894 2.37343C5.57339 1.70843 5.66672 1.21143 6.98116 1.21143H9.01894C10.3334 1.21143 10.4345 1.73643 10.5512 2.38043L10.7223 3.29043"
                              stroke="#E53535"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M13.3279 6.20935L12.8224 13.2584C12.7368 14.3574 12.6668 15.2114 10.4968 15.2114H5.50347C3.33347 15.2114 3.26347 14.3574 3.17792 13.2584L2.67236 6.20935"
                              stroke="#E53535"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M6.70117 11.3615H9.29117"
                              stroke="#E53535"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M6.05542 8.5614H9.94431"
                              stroke="#E53535"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </StyledReservationSummaryItemAction>
                      </StyledReservationSummaryItemActionWrapper>
                    </StyledTicketSummaryPhasePriceCalculation>
                  </StyledTicketSummaryPhasePriceListWrapper>
                </StyledTicketSummaryPhasePriceListItem>
              </StyledReservationSummaryItem>
            ))}
          </StyledReservationSummaryItemWrapper>

          <StyledHorizontalLine />

          <StyledTicketSummaryTotalTicketContainer>
            <StyledTicketSummaryTotalTicketText>
              Total Reservation:
            </StyledTicketSummaryTotalTicketText>
            <StyledTicketSummaryTotalTicketCount>
              {reservations.length}
            </StyledTicketSummaryTotalTicketCount>
          </StyledTicketSummaryTotalTicketContainer>
          <StyledTicketSummaryTotalAmountContainer>
            {" "}
            <StyledTicketSummaryTotalAmountText>
              Total Amount:
            </StyledTicketSummaryTotalAmountText>
            <StyledTicketSummaryTotalAmountCount>
              {totalAmount}
            </StyledTicketSummaryTotalAmountCount>
          </StyledTicketSummaryTotalAmountContainer>
        </StyledTicketSummaryWrapper>
      </Card>
      {focusedResObj && (
        <ReservationInfoModal
          isOpen={isInfoModalOpen}
          onOk={onOkInfoReservation}
          reservation={focusedResObj}
        />
      )}
      {focusedResObj && (
        <DeleteReservationModal
          isOpen={isDeleteModalOpen}
          onConfirm={onConfirmRemoveReservation}
          onCancel={onCancelRemoveReservation}
          reservation={focusedResObj}
        />
      )}
    </StyledReservationSummaryContainer>
  );
};

export default ReservationSummary;
