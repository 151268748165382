import { useMutation } from "@tanstack/react-query";
import BulkCartSeatPayload from "@/apiModels/CartTableAPIModel";
import APIClient from "@/services/APIClient";
import BaseServices from "@/services/BaseServices";

const useBulkCartSeat = ({
  cb,
  cbError,
  cbSuccess
}: IMutationHookProps<BulkCartTableResponse>) => {
  return useMutation<
    BulkCartTableResponse,
    CustomError<RevelErrorResponse>,
    BulkCartTableRequest<BulkCartSeatPayload>
  >({
    mutationFn: ({ payload, eventId }) => {
      const apiClient = new APIClient<BulkCartSeatPayload>(
        BaseServices.SEAT_RESERVATION_SERVICE,
        `/user-app/reservation/event/${eventId}/cart-seat`
      );
      return apiClient.post<BulkCartTableResponse>(payload);
    },
    onSuccess: cbSuccess,
    onError: cbError,
    onMutate: cb
  });
};

export default useBulkCartSeat;
