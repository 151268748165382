import APIClient from "@/services/APIClient";
import { useQuery } from "@tanstack/react-query";
import EventAPIModel from "@/apiModels/ReservationScheduleAPIModel";
import BaseServices from "@/services/BaseServices";

interface Props {
  scheduleQrCode?: string;
}

const useEvent = ({ scheduleQrCode }: Props) => {
  const apiClient = new APIClient<EventAPIModel>(
    BaseServices.SEAT_RESERVATION_SERVICE,
    `/user-app/event/qr/${scheduleQrCode}/event-detail`
  );

  return useQuery<EventAPIModel, Error>({
    queryKey: ["event", scheduleQrCode],
    queryFn: () => apiClient.get(),
    enabled: !!scheduleQrCode,
    retry: false
  });
};

export default useEvent;
